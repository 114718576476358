import {GvfService} from "../../gvf/js/gvf-service";

class FcmService{
    constructor(){

    }

    sendTokenToServer(currentToken){
        if(!this.isTokenSentToServer()){
            GvfService.endpoint("/cp/cp-device/register-token",{token: currentToken}).then(
                () => {
                    this.setTokenSentToServer(true);
                }
            );
        }
    }

    isTokenSentToServer(){
        return window.localStorage.getItem("sentToServer")==="1";
    }

    setTokenSentToServer(sent){
        window.localStorage.setItem("sentToServer",sent?"1":"0");
    }

    requestPermission(){
        Notification.requestPermission().then(() => {
            this.messaging.getToken().then(
                (refreshedToken) => {
                    this.setTokenSentToServer(false);
                    this.sendTokenToServer(refreshedToken);
                }
            ).catch(
                ()=>{

                }
            );
        });
    }
}

const s_FcmService = new FcmService();
export {s_FcmService as FcmService};